var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"appContainer"},[_c('Header',{style:({ 'padding-top': (_vm.headerHeight + "px") })}),_c('Hero',{attrs:{"image":_vm.randomImage,"title":"Your secured loan is just a few clicks away","leading":"When you want extra flexibility or a more competitive rate,\n      flexible loans are a great choice. Secured against your property, they\n      help you access the money you need at a rate that’s right.","points":[
        'Simple, easy application',
        'Whatever your credit history, we can help',
        'Includes a free property valuation',
        'No upfront fees or hidden charges',
        '5-star customer service'
        ]}}),_c('TrustPilot'),_c('InfoSection',{attrs:{"image":"happy.png","title":"No hassles or headaches.","leading":"We make it easy to find a great deal, and with our simple application\n      process, you’ll get your money without hassles or headaches along the way.","points":[
        'Loans from £5,000 to £500,000',
        'Interest Rates From Only 6.3%',
        'All credit profiles considered',
        'Expert qualified Team to get you the right deal',
        'Results and speed on completion powered by our Click Tech platform',
        'As a leading Credit Broker and not a lender we search a panel of lenders',
        'Fixed and variable terms available'
      ],"counter":true,"swap":true}}),_c('InfoSection',{attrs:{"image":"searching","title":"We work hard - you get the best deal."}},[_c('p',{staticClass:"mb-3"},[_vm._v("Because we’re a credit broker, not a lender, we’re able to search a panel of leading lenders to find the loan that suits you best.")]),_c('p',[_vm._v("Got a less-than-perfect credit history? No need to worry.")]),_c('p',[_vm._v("Got CCJs or defaults? The chances are, we’ll still be able to help.")]),_c('p',[_vm._v("Hundreds of products, loads of options, our technology cuts through noise and our experts will advise on most suitable option for you.")]),_c('RepExample')],1),_c('HowItWorks'),_c('Contact'),_c('Lenders'),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }