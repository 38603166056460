var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"appContainer"},[_c('Header',{style:({ 'padding-top': (_vm.headerHeight + "px") })}),_c('Hero',{attrs:{"image":"debt.png","title":"It’s easy to do debt better.","leading":"Every day, Believe helps people just like you to put debt\n      worries behind them with an affordable secured loan. ","points":[
        'Solutions for any credit history',
        'No impact on your credit score',
        'No upfront fees',
        'Consolidate your debts',
        'Reduce outgoings – one simple monthly payment'
        ]}}),_c('TrustPilot'),_c('InfoSection',{attrs:{"image":"celebrate.png","title":"No hassles or headaches.","leading":"By using a loan to pay off your existing debts – imagine\n      that! – you get a single, manageable monthly payment and a life\n      without all that worry and stress.","points":[
        'Loans from £5,000 to £500,000',
        'Interest Rates From Only 6.3%',
        'All credit profiles considered',
        'As a leading Credit Broker and not a lender we search a panel of lenders',
        'Pay off credit and store cards and loan debts into one affordable payment',
        'Simple, stress-free application process you control!'
      ],"counter":true,"swap":true}}),_c('InfoSection',{attrs:{"image":"searching","title":"We work hard - you get the best deal.","leading":"Because we’re a credit broker, not a lender, we’re able\n      to search a panel of leading lenders to find the loan that suits you best."}},[_c('p',[_vm._v("Got a less-than-perfect credit history? No need to worry.")]),_c('p',[_vm._v("Got CCJs or defaults? The chances are, we’ll still be able to help.")]),_c('p',[_vm._v("Hundreds of products, loads of options, our technology cuts through the noise and our experts will advise on the most suitable option for you.")]),_c('RepExample')],1),_c('HowItWorks'),_c('Contact'),_c('Lenders'),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }