<template>
  <div>
    <Header :style="{ 'padding-top': `${headerHeight}px` }" />
    <div class="hero-area">
      <div class="container">
        <div class="page-header">
          <div class="row" style="height:80vh">
            <div class="col-md-6 col-sm-12">
              <div class="hero-block">
                <h4 class="overline">Believe</h4>
                <h2 class="title">Thank you. One of our experts will be in contact shortly </h2>
                <p class="callingFrom">(calling from number {{ branding.phone }}) to run through your application</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../Sections/Header.vue'
import Footer from '../Sections/Footer.vue'
import { Bus } from '../bus'
import { branding } from '../branding'

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      branding,
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  }
}
</script>