<template>
    <div ref="appContainer">
      <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
      <Hero
          :image="randomImage"
          title="Your secured loan is just a few clicks away"
          leading="When you want extra flexibility or a more competitive rate,
          flexible loans are a great choice. Secured against your property, they
          help you access the money you need at a rate that’s right."
          :points="[
            'Simple, easy application',
            'Whatever your credit history, we can help',
            'Includes a free property valuation',
            'No upfront fees or hidden charges',
            '5-star customer service'
            ]"
      />
      <TrustPilot />
      <InfoSection
          image="happy.png"
          title="No hassles or headaches."
          leading="We make it easy to find a great deal, and with our simple application
          process, you’ll get your money without hassles or headaches along the way."
          :points="[
            'Loans from £5,000 to £500,000',
            'Interest Rates From Only 6.3%',
            'All credit profiles considered',
            'Expert qualified Team to get you the right deal',
            'Results and speed on completion powered by our Click Tech platform',
            'As a leading Credit Broker and not a lender we search a panel of lenders',
            'Fixed and variable terms available'
          ]"
          :counter="true"
          :swap="true"
      />
      <InfoSection
          image="searching"
          title="We work hard - you get the best deal."
      >
        <p class="mb-3">Because we’re a credit broker, not a lender, we’re able to search a panel of leading lenders to find the loan that suits you best.</p>
        <p>Got a less-than-perfect credit history? No need to worry.</p>
        <p>Got CCJs or defaults? The chances are, we’ll still be able to help.</p>
        <p>Hundreds of products, loads of options, our technology cuts through noise and our experts
          will advise on most suitable option for you.</p>
        <RepExample />
      </InfoSection>
      <HowItWorks/>
      <Contact />
      <Lenders />
      <Footer />
    </div>
</template>

<script>
import { Bus } from '../bus'

import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import InfoSection from "../Sections/InfoSection";
import Contact from '../Sections/Contact.vue'
import Lenders from '../Sections/Lenders.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";
import RepExample from "../components/RepExample";

export default {
  name: 'app',
  components: {
    HowItWorks,
    InfoSection,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Lenders,
    Footer,
    RepExample
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    },
    randomImage() {
      let images = ['secured','secured-alt'];
      let rnd = images[Math.floor(Math.random() * images.length)] + '.png';
      return rnd;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
          iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  }
}
</script>
