<template>
    <div ref="appContainer">
      <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
      <Hero
          image="decorate.png"
          title="Get houseproud with a home improvement loan."
          leading="A home improvement loan is quick and simple to arrange, so
          you’ll soon be able to give your home a new lease of life."
          :points="[
            'Simple, stress-free application process',
            'Get your hands on your money fast',
            'Competitive rates from our panel of lenders',
            '5-star customer service',
            'No upfront fees or hidden charges'
            ]"
      />
      <TrustPilot />
      <InfoSection
          image="home.png"
          title="No hassles or headaches."
          leading="A home improvement loan could transform the place you live. From ﬁnding
          your competitive quote to completing our simple, straightforward application
          process, this is home improvement without hassles."
          :points="[
            'Homeowner Loans from £5,000 to £500,000',
            'Interest Rates From Only 6.3%',
            'All credit profiles considered',
            'Use your equity to make those improvements you’ve always wanted',
            'Fixed and variable terms available',
            'Improve your home and increase your house value!'
          ]"
          :counter="true"
          :swap="true"
      />
      <InfoSection
          image="searching"
          title="We work hard - you get the best deal."
          leading="Because we’re a credit broker, not a lender, we’re able
          to search a panel of leading lenders to find the loan that suits you best."
      >
        <p>Got a less-than-perfect credit history? No need to worry.</p>
        <p>Got CCJs or defaults? The chances are, we’ll still be able to help.</p>
        <p>Hundreds of products, loads of options, our technology cuts through the noise and our experts
          will advise on most the suitable option for you.</p>
        <RepExample />
      </InfoSection>
      <HowItWorks/>
      <Contact />
      <Lenders />
      <Footer />
    </div>
</template>

<script>
import { Bus } from '../bus'

import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import InfoSection from "../Sections/InfoSection";
import Contact from '../Sections/Contact.vue'
import Lenders from '../Sections/Lenders.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";
import RepExample from "../components/RepExample";

export default {
  name: 'app',
  components: {
    HowItWorks,
    InfoSection,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Lenders,
    Footer,
    RepExample
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
          iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  }
}
</script>
