
<template>
  <div v-if="branding.trustPilot.businessUnitId.length > 0">
    <div class="container">
      <div class="row py-3 py-sm-5">
        <div class="col-12">
        </div>
        <div class="trustpilot-widget"
             ref="trustpilot"
             data-locale="en-GB"
             data-template-id="53aa8912dec7e10d38f59f36"
             :data-businessunit-id="branding.trustPilot.businessUnitId"
             data-style-height="140px"
             data-style-width="100%"
             data-theme="light" data-stars="4,5">
          <a :href="`https://uk.trustpilot.com/review/${branding.website.shortUrl}`" target="_blank" rel="noopener">Trustpilot</a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {branding} from "../branding";
export default {
  mounted(){
    if (branding.trustPilot.businessUnitId.length > 0) {
      const body = document.getElementsByTagName("body")[0];
      if (!window.TrustPilotScript) {
        window.TrustPilotScript = document.createElement("script");
        window.TrustPilotScript.setAttribute("src", "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js");
        body.appendChild(window.TrustPilotScript);
      }
      setTimeout(() => {
        window.Trustpilot.loadFromElement(this.$refs.trustpilot);
      }, 500);
    }
  },
  data(){
    return {
      branding
    }
  }
}
</script>

<style>
.trustpilot-widget{
  width: 100%;
}

@media (max-width: 426px){
  .trustpilot-widget iframe {
    height: 275px !important;
    width: 320px !important;
    margin: 0 auto;
  }
}
</style>