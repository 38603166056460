<template>
  <div>
    <div class="container-fluid lenderRow mt-4 mb-4">
      <div class="d-flex justify-content-center lenderRow align-items-center flex-wrap">
        <img src="@/assets/lenders/equifinance.jpg" alt="Equifinance">
        <img src="@/assets/lenders/pepper-money.png" alt="Pepper Money">
        <img src="@/assets/lenders/tandem.png" alt="Tandem">
        <img src="@/assets/lenders/shawbrook.jpg" alt="Shawbrook Bank">
        <img src="@/assets/lenders/together.png" alt="Together">
        <img src="@/assets/lenders/utb.jpg" alt="United Trust Bank">
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        
    }
</script>

<style>
.lenderRow img {
    height: auto;
    max-width: 150px;
}
.lenderRow img {
    padding: 10px 10px 10px 0px;
}
</style>