<template>
    <div ref="appContainer" class="lander-totally-money">
      <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
      <Hero
          no-mobile-image
          image="home.png"
          title="Unlock life with a quick and easy loan"
          leading="From big purchases to holidays and home improvements,
          a loan unlocks so many possibilities - and the money could be
          with you very soon"
          :points="[
            'Simple, easy application',
            'We search lenders to get you the best deal',
            '5-star customer service',
            'No impact on your credit score',
            'No upfront fees or hidden charges'
            ]"
          disable-tenant
      >
        <span slot="form">
          <img src="@/assets/misc/totally-money.png" class="img-fluid pb-3" />
        </span>
      </Hero>
      <div class="container pt-0 pt-sm-3 pt-md-4 pt-lg-5">
        <div class="row">
          <div class="col-12 offset-0 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
            <img src="@/assets/MSA-2022-Winner.png" class="img-fluid" alt="Mortgage Strategy Awards, Best Second Charge Broker, Winner 2022">
          </div>
        </div>
      </div>
      <TrustPilot />
      <InfoSection
          image="car"
          title="No hassles or headaches."
          leading="We've developed our own advanced technology platform
          to help get your quote quickly - and your money could be with
          you in just ten days."
          :points="[
            'Loans from £5,000 to £500,000',
            'Interest Rates From Only 6.3%',
            'All credit profiles considered',
            'As a leading Credit Broker and not a lender we’ve more offers and options',
            'Real rates tailored just for you',
            'Fast secure online process you control!',
          ]"
          :counter="true"
          :swap="true"
      />
      <InfoSection
          image="searching"
          title="We work hard - you get the best deal."
      >
        <p class="mt-3">Because we’re a credit broker, not a lender, we’re able to search a panel
          of leading lenders to find the loan that suits you best.</p>
        <p>Got a less-than-perfect credit history? No need to worry.</p>
        <p>Got CCJs or defaults? The chances are, we’ll still be able to help.</p>
        <p>Hundreds of products, loads of options, our technology cuts through the noise and our experts
          will advise on the most suitable option for you.</p>
        <RepExample />
      </InfoSection>
      <HowItWorks/>
      <Contact />
      <Lenders />
      <Footer />
    </div>
</template>

<script>
import { Bus } from '../../bus'

import Header from '../../Sections/Header.vue'
import Hero from '../../Sections/Hero.vue'
import TrustPilot from '../../Sections/TrustPilot.vue'
import InfoSection from "../../Sections/InfoSection";
import Contact from '../../Sections/Contact.vue'
import Lenders from '../../Sections/Lenders.vue'
import Footer from '../../Sections/Footer.vue'
import HowItWorks from "../../Sections/HowItWorks";
import RepExample from "../../components/RepExample";

export default {
  name: 'app',
  components: {
    HowItWorks,
    InfoSection,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Lenders,
    Footer,
    RepExample
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
          iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  }
}
</script>

<style type="text/css">
.lander-totally-money .hero-area .container .hero-area__content__mobile .hero h1.title {
  display: none;
}
.lander-totally-money .hero-area .container .hero-area__content__mobile .hero div.lander-logo {
  width:90%;
  margin:0 auto;
  padding-bottom:100px;
 }
</style>
