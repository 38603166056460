<template>
  <div class="d-flex w-100 h-100">
    <div id="form-1" class="apply-buttons d-flex w-100 h-100 text-center justify-content-center p-0 m-0">
      <button :class="`btn btn-xl btn-secondary align-middle d-flex flex-column align-items-center justify-content-center ctaButton p-3 ${ buttonClass }`"
              type="button"
              name="button"
              @click="onButtonClicked('select')"
      >
        Let's get started!
      </button>
    </div>
<!--    <div class="apply-popup" :class="{ open: applyPopup }">-->
<!--      <div>-->
<!--        <img @click="applyPopup = false" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNNDQzLjYsMzg3LjFMMzEyLjQsMjU1LjRsMTMxLjUtMTMwYzUuNC01LjQsNS40LTE0LjIsMC0xOS42bC0zNy40LTM3LjZjLTIuNi0yLjYtNi4xLTQtOS44LTRjLTMuNywwLTcuMiwxLjUtOS44LDQgIEwyNTYsMTk3LjhMMTI0LjksNjguM2MtMi42LTIuNi02LjEtNC05LjgtNGMtMy43LDAtNy4yLDEuNS05LjgsNEw2OCwxMDUuOWMtNS40LDUuNC01LjQsMTQuMiwwLDE5LjZsMTMxLjUsMTMwTDY4LjQsMzg3LjEgIGMtMi42LDIuNi00LjEsNi4xLTQuMSw5LjhjMCwzLjcsMS40LDcuMiw0LjEsOS44bDM3LjQsMzcuNmMyLjcsMi43LDYuMiw0LjEsOS44LDQuMWMzLjUsMCw3LjEtMS4zLDkuOC00LjFMMjU2LDMxMy4xbDEzMC43LDEzMS4xICBjMi43LDIuNyw2LjIsNC4xLDkuOCw0LjFjMy41LDAsNy4xLTEuMyw5LjgtNC4xbDM3LjQtMzcuNmMyLjYtMi42LDQuMS02LjEsNC4xLTkuOEM0NDcuNywzOTMuMiw0NDYuMiwzODkuNyw0NDMuNiwzODcuMXoiLz48L3N2Zz4="/>-->
<!--        <button :class="`btn btn-xl btn-secondary align-middle d-flex flex-column align-items-center justify-content-center ctaButton my-3 px-5 py-3 ${ buttonClass }`"-->
<!--                  type="button"-->
<!--                  name="button"-->
<!--                  @click="onButtonClicked('Homeowner')"-->
<!--          >I am a homeowner-->
<!--          </button>-->

<!--        <button :class="`btn btn-xl btn-secondary align-middle d-flex flex-column align-items-center justify-content-center ctaButton my-3 px-5 py-3 ${ buttonClass }`"-->
<!--                  type="button"-->
<!--                  name="button"-->
<!--                  @click="onButtonClicked('Tenant')"-->
<!--          >I am a tenant-->
<!--          </button>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "ApplyButtons",
  data() {
    return {
      applyPopup: false
    }
  },
  computed: {
    buttonClass() {
      if (this.$route.name === "DebtConsolidation") {
        return "green"
      } //else if (this.$route.name === "Home") {
        //return "blue"
     // }
      return "red";
    }
  },
  methods: {
    onButtonClicked(form) {
      this.$emit("selectForm", form);
    }
  }
}
</script>

<style  lang="scss" scoped>

#form-1 {
  padding:0 !important;;
}

.apply-buttons {
  flex-direction: column;
  margin-top:10px;
  justify-content: space-between;
}

.apply-buttons h5 {
  font-size: 1.4rem;
  color:#dd1d42 !important;
}

.ctaButton {
  font-size:1rem;
  font-weight: bold;
  border-radius: 1rem;
}

.ctaButton.green {
  background-color: #56B27F;
  border-color: #56B27F;
  &:active,
  &:focus,
  &:active:focus {
    background-color: #56B27F !important;
    border-color: #56B27F !important;
    box-shadow: 0 0 0 0.2rem rgba(86, 178, 127, 0.5) !important;
  }
}

.ctaButton.blue {
  background-color: #17174C;
  border-color: #17174C;
  &:active,
  &:focus,
  &:active:focus {
    background-color: #17174C !important;
    border-color: #17174C !important;
    box-shadow: 0 0 0 0.2rem rgba(23, 23, 76, 0.5) !important;
  }
}

.ctaButton.red {
  background-color: #dd1d42;
  border-color: #dd1d42;
  &:active,
  &:focus,
  &:active:focus  {
    background-color: #dd1d42 !important;
    border-color: #dd1d42 !important;
    box-shadow: 0 0 0 0.2rem rgba(221, 129, 66, 0.5) !important;
  }
}

.apply-buttons .ctaButton p {
  font-size:0.9rem;
  font-weight: normal;
  padding:0;
  margin:0;
}

.apply-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  align-items: center;
  justify-content: center;
  min-width:320px;

  div {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 1em;
    background: white;
    padding: 2em;
    border-radius: 0.5em;
    color:black;

    img {
      position:absolute;
      top:10px;
      right:10px;
      color:black;
      cursor:pointer;
      width:1.5rem;
    }

    button {
      width: 100%;
    }
  }

  &.open {
     display: flex;
   }
}

@media (max-width: 991px){
  .apply-buttons h5 {
    font-size: 1rem;
  }
  .apply-buttons .ctaButton {
    font-size: 1rem;
  }
  .apply-buttons .ctaButton p {
    font-size: 0.8rem;
  }
}

@media (max-width: 767px){
  .apply-buttons h5 {
    font-size: 1.4em;
  }
  .apply-buttons .ctaButton {
    font-size: 1.4rem;
  }
}

@media (max-width: 420px){
  .apply-buttons h5 {
    font-size: 1em;
  }
  .apply-buttons .ctaButton {
    font-size: 1rem;
  }
}

@media (max-width: 360px){
  .apply-buttons h5 {
    font-size: 1em;
  }
  .apply-buttons .ctaButton {
    font-size: 1rem;
  }
}

</style>