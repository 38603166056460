import Home from './Views/Home.vue';
import Secured from './Views/Secured.vue'
import ThankYou from './Views/ThankYou.vue';
import PrivacyPolicy from './Views/Policy/PrivacyPolicy.vue';
import CookiePolicy from './Views/Policy/CookiePolicy.vue';
import ComplaintPolicy from './Views/Policy/ComplaintsPolicy.vue';
import TermsAndConditions from "./Views/Policy/TermsAndConditions.vue";
import HomeImprovements from "./Views/HomeImprovements.vue";
import DebtConsolidation from "./Views/DebtConsolidation.vue";
import TotallyMoney from "./Views/Landers/TotallyMoney.vue";
//import ClearScore from "./Views/ClearScore.vue";

const root = "";

export const routes = [
    {
        name: "Home",
        path: "/",
        component: Home,
        beforeEnter: async (to, from, next) => {
            const tracking = await window.ClickAppTrack;
            const source = tracking?.query?.utm_source.toString() ?? "";
            if (source.toLowerCase().startsWith("tm")) {
                next({ name: "Lander-TotallyMoney", query: to.query });
            }
            next()
        }
    },
  /*
    {
        name: "ClearScore",
        path: "/clearscore",
        component: ClearScore
    },
    */
    // *** ********************** *//
    // *** Landers                *//
    // *** ********************** *//
    {
        name: "Lander-TotallyMoney",
        path: "/",
        component: TotallyMoney
    },
    // *** ********************** *//
    // *** Normal Pages           *//
    // *** ********************** *//
    {
        name: "Secured",
        path: `${root}/secured`,
        component: Secured
    },
    {
        name: "HomeImprovements",
        path: `${root}/home-improvements`,
        component: HomeImprovements
    },
    {
        name: "DebtConsolidation",
        path: `${root}/debt-consolidation`,
        component: DebtConsolidation
    },
    {
        name: "Thank You",
        path: `${root}/thank-you`,
        component: ThankYou
    },
    {
        name: "Cookies",
        path: `${root}/cookies`,
        component: CookiePolicy
    },
    {
        name: "Privacy",
        path: `${root}/privacy`,
        component: PrivacyPolicy
    },
    {
        name: "Terms",
        path: `${root}/terms`,
        component: TermsAndConditions
    },
    {
        name: "Complaints",
        path: `${root}/complaints`,
        component: ComplaintPolicy
    },
    // *** ********************** *//
    // *** A/B SPLIT TESTING      *//
    // *** ********************** *//
    {
        name: 'Lander-Select',
        path: `${root}/apply/select`,
        component: Home
    },
    {
        name: 'Lander-Apply',
        path: `${root}/apply`,
        component: Home
    },
    // *** ********************** *//
    // *** OLD LINKS JUST IN CASE *//
    // *** ********************** *//
    {
        path: `${root}/cookie_policy`,
        component: CookiePolicy
    },
    {
        path: `${root}/privacy_policy`,
        component: PrivacyPolicy
    },
    {
        path: `${root}/terms_and_conditions`,
        component: TermsAndConditions
    },
    {
        path: `${root}/complaints_policy`,
        component: ComplaintPolicy
    }
]
