var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"appContainer",staticClass:"lander-totally-money"},[_c('Header',{style:({ 'padding-top': (_vm.headerHeight + "px") })}),_c('Hero',{attrs:{"no-mobile-image":"","image":"home.png","title":"Unlock life with a quick and easy loan","leading":"From big purchases to holidays and home improvements,\n      a loan unlocks so many possibilities - and the money could be\n      with you very soon","points":[
        'Simple, easy application',
        'We search lenders to get you the best deal',
        '5-star customer service',
        'No impact on your credit score',
        'No upfront fees or hidden charges'
        ],"disable-tenant":""}},[_c('span',{attrs:{"slot":"form"},slot:"form"},[_c('img',{staticClass:"img-fluid pb-3",attrs:{"src":require("@/assets/misc/totally-money.png")}})])]),_vm._m(0),_c('TrustPilot'),_c('InfoSection',{attrs:{"image":"car","title":"No hassles or headaches.","leading":"We've developed our own advanced technology platform\n      to help get your quote quickly - and your money could be with\n      you in just ten days.","points":[
        'Loans from £5,000 to £500,000',
        'Interest Rates From Only 6.3%',
        'All credit profiles considered',
        'As a leading Credit Broker and not a lender we’ve more offers and options',
        'Real rates tailored just for you',
        'Fast secure online process you control!' ],"counter":true,"swap":true}}),_c('InfoSection',{attrs:{"image":"searching","title":"We work hard - you get the best deal."}},[_c('p',{staticClass:"mt-3"},[_vm._v("Because we’re a credit broker, not a lender, we’re able to search a panel of leading lenders to find the loan that suits you best.")]),_c('p',[_vm._v("Got a less-than-perfect credit history? No need to worry.")]),_c('p',[_vm._v("Got CCJs or defaults? The chances are, we’ll still be able to help.")]),_c('p',[_vm._v("Hundreds of products, loads of options, our technology cuts through the noise and our experts will advise on the most suitable option for you.")]),_c('RepExample')],1),_c('HowItWorks'),_c('Contact'),_c('Lenders'),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container pt-0 pt-sm-3 pt-md-4 pt-lg-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 offset-0 col-md-8 offset-md-2 col-lg-8 offset-lg-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/MSA-2022-Winner.png"),"alt":"Mortgage Strategy Awards, Best Second Charge Broker, Winner 2022"}})])])])}]

export { render, staticRenderFns }