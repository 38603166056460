<template>
  <div />
</template>
<script>
const loader = new Promise((resolve, reject) => {
  if (window.ClickAppForm) {
    resolve(window.ClickAppForm);
    return;
  }
  const script = document.createElement("script");
  script.src = process.env.VUE_APP_CLICK_APPLY_WIDGET;
  script.crossOrigin = "anonymous";
  script.onload = () => {
    resolve(window.ClickAppForm);
  };
  script.onerror = reject;
  document.body.appendChild(script);
});

window.addEventListener("message", event => {
  if (event.data.type === "redirectOnComplete") {
    if (event.data.state.lead.created) {
      if (event.data.state.answers.residentialStatus === "Homeowner") {
        window.location.assign(event.data.state.lead.state.quote_link || "/thank-you");
      }
    }
  }
});

export default {
  props: {
    homeOwnerMode: Boolean,
    hybridMode: Boolean
  },
  async mounted() {
    const tracking = await window.ClickAppTrack;
    tracking.applicationURL = location.href;
    await loader.then(ClickAppForm => {
      ClickAppForm.default(this.$el, {
        leadSource: "Believe Loans Website",
        phoneNumber: "01302 590 853",
        documentURLs: {
          privacyNotice: "/privacy",
          terms: "/terms"
        },
        tracking: tracking,
        initialValues: {...this.$route.query, "residentialStatus":  this.hybridMode || this.homeOwnerMode ? "Homeowner" : "Private Tenant"},
        minLoanAmount: this.homeOwnerMode || this.hybridMode ? 5000 : 1000,
        maxLoanAmount: this.homeOwnerMode || this.hybridMode ? 500000 : 25000,
        mode: this.hybridMode ? "Hybrid" : this.homeOwnerMode ? "Homeowner" : "Private Tenant",
        believe: true,
        redirectOnComplete: this.hybridMode ? false : this.homeOwnerMode ? false : true
      });
    });
  }
};
</script>
