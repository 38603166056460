<template>
  <div class="h-100 mt-4">
    <apply-button @selectForm="showAppForm" v-if="useButton"   />
    <apply-buttons @selectForm="showAppForm" v-else />
<!--    <apply-dropdown @selectForm="showAppForm" v-else />-->
  </div>
</template>

<script>
// import ApplyDropdown from "./Apply-Dropdown";
import ApplyButtons from "./Apply-Buttons";
import ApplyButton from "./Apply-Button";
export default {
  name: "ApplyRouter",
  components: {ApplyButton, ApplyButtons}, //, ApplyDropdown},
  props: {
    currentLendingAmount: Number
  },
  methods: {
    showAppForm(form) {
      this.$emit('showAppForm', form);
      window.scrollTo(0, 0);
    }
  },
  computed: {
    useButton() {
      return this.$route.name === "Lander-Apply" || (this.$route.query?.utm_source?.toString() ?? "").toLowerCase() === "tm1";
    }
  }
}
</script>