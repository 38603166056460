<template>
    <div ref="appContainer">
      <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
      <Hero
          image="home.png"
          title="Unlock life with a quick and easy loan"
          leading="From big purchases to holidays and home improvements,
          a loan unlocks so many possibilities - and the money could be
          with you very soon"
          :points="[
            'Simple, easy application',
            'We search lenders to get you the best deal',
            '5-star customer service',
            'No impact on your credit score',
            'No upfront fees or hidden charges'
            ]"
      />
      <div class="container pt-0 pt-sm-3 pt-md-4 pt-lg-5">
        <div class="row align-items-center">
          <div class="col-6 offset-3 col-sm-3 offset-sm-0">
            <img src="@/assets/mf-finalist-logo-2024-broker-rgb.png" class="img-fluid mb-3 mb-sm-0" alt="Moneyfacts Awards 2024 Finalist">
          </div>
          <div class="col-12 col-sm-9">
            <img src="@/assets/MSA-2022-Winner.png" class="img-fluid" alt="Mortgage Strategy Awards, Best Second Charge Broker, Winner 2022">
          </div>
        </div>
      </div>
      <TrustPilot />
      <Hero
          column-class="col-12 col-md-7 col-lg-6 col-xl-5 py-5"
          image="home-app.png"
          title="Customer friendly technology"
      >
        <p>Our customers are at the heart of everything we do – a fact supported by our thousands of five-star customer reviews across all brands.</p>
        <p>By empowering consumers to interact with us on multiple channels – including customer portals, secure apps and bots – we give them choice and control, along with the reassurance of support via our advisors and agents.</p>
      </Hero>
      <InfoSection
          image="searching"
          title="We work hard - you get the best deal."
      >
        <p class="mt-3">Because we’re a credit broker, not a lender, we’re able to search a panel
          of leading lenders to find the loan that suits you best.</p>
        <p>Got a less-than-perfect credit history? No need to worry.</p>
        <p>Got CCJs or defaults? The chances are, we’ll still be able to help.</p>
        <p>Hundreds of products, loads of options, our technology cuts through the noise and our experts
          will advise on the most suitable option for you.</p>
        <RepExample />
      </InfoSection>
      <Hero
          column-class="col-12 col-md-7 offset-md-5 col-lg-6 offset-lg-6 col-xl-5 offset-xl-7 py-2"
          background-style="background-position: 65% center;"
          image="home-hassle-free.png"
          title="No hassles or headaches."
          leading="We've developed our own advanced technology platform
          to help get your quote quickly - and your money could be with
          you in just ten days."
          :points="[
            'Loans from £5,000 to £500,000',
            'Interest Rates From Only 6.3%',
            'All credit profiles considered',
            'As a leading Credit Broker and not a lender we’ve more offers and options',
            'Real rates tailored just for you',
            'Fast secure online process you control!',
          ]"
          bullet="circle"
          no-form
          />
      <HowItWorks/>
      <Contact />
      <Lenders />
      <Footer />
    </div>
</template>

<script>
import { Bus } from '../bus'

import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import Contact from '../Sections/Contact.vue'
import Lenders from '../Sections/Lenders.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";
import RepExample from "../components/RepExample";
import InfoSection from "../Sections/InfoSection.vue";

export default {
  name: 'app',
  components: {
    HowItWorks,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Lenders,
    Footer,
    RepExample,
    InfoSection
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
          iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  }
}
</script>
